export const whatsappCountries = [
  {
    "dialCode": "1",
    "name": "Canada",
    "code": "CA"
  },
  {
    "dialCode": "1",
    "name": "United States",
    "code": "US"
  },
  // {
  //   "dialCode": "20",
  //   "name": "Egypt",
  //   "code": "EG"
  // },
  // {
  //   "dialCode": "211",
  //   "name": "South Sudan",
  //   "code": "SS"
  // },
  // {
  //   "dialCode": "212",
  //   "name": "Morocco",
  //   "code": "MA"
  // },
  // {
  //   "dialCode": "213",
  //   "name": "Algeria",
  //   "code": "DZ"
  // },
  // {
  //   "dialCode": "216",
  //   "name": "Tunisia",
  //   "code": "TN"
  // },
  // {
  //   "dialCode": "218",
  //   "name": "Libya",
  //   "code": "LY"
  // },
  // {
  //   "dialCode": "220",
  //   "name": "Gambia",
  //   "code": "GM"
  // },
  // {
  //   "dialCode": "221",
  //   "name": "Senegal",
  //   "code": "SN"
  // },
  // {
  //   "dialCode": "222",
  //   "name": "Mauritania",
  //   "code": "MR"
  // },
  // {
  //   "dialCode": "223",
  //   "name": "Mali",
  //   "code": "ML"
  // },
  // {
  //   "dialCode": "225",
  //   "name": "Ivory Coast",
  //   "code": "CI"
  // },
  // {
  //   "dialCode": "226",
  //   "name": "Burkina Faso",
  //   "code": "BF"
  // },
  // {
  //   "dialCode": "227",
  //   "name": "Niger",
  //   "code": "NE"
  // },
  // {
  //   "dialCode": "228",
  //   "name": "Togo",
  //   "code": "TG"
  // },
  // {
  //   "dialCode": "229",
  //   "name": "Benin",
  //   "code": "BJ"
  // },
  // {
  //   "dialCode": "231",
  //   "name": "Liberia",
  //   "code": "LR"
  // },
  // {
  //   "dialCode": "232",
  //   "name": "Sierra Leone",
  //   "code": "SL"
  // },
  // {
  //   "dialCode": "233",
  //   "name": "Ghana",
  //   "code": "GH"
  // },
  // {
  //   "dialCode": "234",
  //   "name": "Nigeria",
  //   "code": "NG"
  // },
  // {
  //   "dialCode": "235",
  //   "name": "Chad",
  //   "code": "TD"
  // },
  // {
  //   "dialCode": "237",
  //   "name": "Cameroon",
  //   "code": "CM"
  // },
  // {
  //   "dialCode": "241",
  //   "name": "Gabon",
  //   "code": "GA"
  // },
  // {
  //   "dialCode": "242",
  //   "name": "Republic of the Congo (Brazzaville)",
  //   "code": "CG"
  // },
  // {
  //   "dialCode": "244",
  //   "name": "Angola",
  //   "code": "AO"
  // },
  // {
  //   "dialCode": "245",
  //   "name": "Guinea-Bissau",
  //   "code": "GW"
  // },
  // {
  //   "dialCode": "249",
  //   "name": "Sudan",
  //   "code": "SD"
  // },
  // {
  //   "dialCode": "250",
  //   "name": "Rwanda",
  //   "code": "RW"
  // },
  // {
  //   "dialCode": "251",
  //   "name": "Ethiopia",
  //   "code": "ET"
  // },
  // {
  //   "dialCode": "252",
  //   "name": "Somalia",
  //   "code": "SO"
  // },
  // {
  //   "dialCode": "254",
  //   "name": "Kenya",
  //   "code": "KE"
  // },
  // {
  //   "dialCode": "255",
  //   "name": "Tanzania",
  //   "code": "TZ"
  // },
  // {
  //   "dialCode": "256",
  //   "name": "Uganda",
  //   "code": "UG"
  // },
  // {
  //   "dialCode": "257",
  //   "name": "Burundi",
  //   "code": "BI"
  // },
  // {
  //   "dialCode": "258",
  //   "name": "Mozambique",
  //   "code": "MZ"
  // },
  // {
  //   "dialCode": "260",
  //   "name": "Zambia",
  //   "code": "ZM"
  // },
  // {
  //   "dialCode": "261",
  //   "name": "Madagascar",
  //   "code": "MG"
  // },
  // {
  //   "dialCode": "264",
  //   "name": "Namibia",
  //   "code": "NA"
  // },
  // {
  //   "dialCode": "265",
  //   "name": "Malawi",
  //   "code": "MW"
  // },
  // {
  //   "dialCode": "266",
  //   "name": "Lesotho",
  //   "code": "LS"
  // },
  // {
  //   "dialCode": "267",
  //   "name": "Botswana",
  //   "code": "BW"
  // },
  // {
  //   "dialCode": "268",
  //   "name": "Swaziland",
  //   "code": "SZ"
  // },
  // {
  //   "dialCode": "27",
  //   "name": "South Africa",
  //   "code": "ZA"
  // },
  // {
  //   "dialCode": "291",
  //   "name": "Eritrea",
  //   "code": "ER"
  // },
  // {
  //   "dialCode": "30",
  //   "name": "Greece",
  //   "code": "GR"
  // },
  // {
  //   "dialCode": "31",
  //   "name": "Netherlands",
  //   "code": "NL"
  // },
  // {
  //   "dialCode": "32",
  //   "name": "Belgium",
  //   "code": "BE"
  // },
  {
    "dialCode": "33",
    "name": "France",
    "code": "FR"
  },
  // {
  //   "dialCode": "34",
  //   "name": "Spain",
  //   "code": "ES"
  // },
  // {
  //   "dialCode": "351",
  //   "name": "Portugal",
  //   "code": "PT"
  // },
  // {
  //   "dialCode": "353",
  //   "name": "Ireland",
  //   "code": "IE"
  // },
  // {
  //   "dialCode": "355",
  //   "name": "Albania",
  //   "code": "AL"
  // },
  // {
  //   "dialCode": "358",
  //   "name": "Finland",
  //   "code": "FI"
  // },
  // {
  //   "dialCode": "359",
  //   "name": "Bulgaria",
  //   "code": "BG"
  // },
  // {
  //   "dialCode": "36",
  //   "name": "Hungary",
  //   "code": "HU"
  // },
  // {
  //   "dialCode": "370",
  //   "name": "Lithuania",
  //   "code": "LT"
  // },
  // {
  //   "dialCode": "371",
  //   "name": "Latvia",
  //   "code": "LV"
  // },
  // {
  //   "dialCode": "373",
  //   "name": "Moldova",
  //   "code": "MD"
  // },
  // {
  //   "dialCode": "374",
  //   "name": "Armenia",
  //   "code": "AM"
  // },
  // {
  //   "dialCode": "375",
  //   "name": "Belarus",
  //   "code": "BY"
  // },
  {
    "dialCode": "380",
    "name": "Ukraine",
    "code": "UA"
  },
  // {
  //   "dialCode": "381",
  //   "name": "Serbia",
  //   "code": "RS"
  // },
  // {
  //   "dialCode": "385",
  //   "name": "Croatia",
  //   "code": "HR"
  // },
  // {
  //   "dialCode": "386",
  //   "name": "Slovenia",
  //   "code": "SI"
  // },
  // {
  //   "dialCode": "389",
  //   "name": "North Macedonia",
  //   "code": "MK"
  // },
  {
    "dialCode": "39",
    "name": "Italy",
    "code": "IT"
  },
  // {
  //   "dialCode": "40",
  //   "name": "Romania",
  //   "code": "RO"
  // },
  {
    "dialCode": "41",
    "name": "Switzerland",
    "code": "CH"
  },
  // {
  //   "dialCode": "420",
  //   "name": "Czech Republic",
  //   "code": "CZ"
  // },
  // {
  //   "dialCode": "421",
  //   "name": "Slovakia",
  //   "code": "SK"
  // },
  // {
  //   "dialCode": "43",
  //   "name": "Austria",
  //   "code": "AT"
  // },
  {
    "dialCode": "44",
    "name": "United Kingdom",
    "code": "GB"
  },
  // {
  //   "dialCode": "45",
  //   "name": "Denmark",
  //   "code": "DK"
  // },
  // {
  //   "dialCode": "46",
  //   "name": "Sweden",
  //   "code": "SE"
  // },
  // {
  //   "dialCode": "47",
  //   "name": "Norway",
  //   "code": "NO"
  // },
  {
    "dialCode": "48",
    "name": "Poland",
    "code": "PL"
  },
  {
    "dialCode": "49",
    "name": "Germany",
    "code": "DE"
  },
  // {
  //   "dialCode": "502",
  //   "name": "Guatemala",
  //   "code": "GT"
  // },
  // {
  //   "dialCode": "503",
  //   "name": "El Salvador",
  //   "code": "SV"
  // },
  // {
  //   "dialCode": "504",
  //   "name": "Honduras",
  //   "code": "HN"
  // },
  // {
  //   "dialCode": "505",
  //   "name": "Nicaragua",
  //   "code": "NI"
  // },
  // {
  //   "dialCode": "506",
  //   "name": "Costa Rica",
  //   "code": "CR"
  // },
  // {
  //   "dialCode": "507",
  //   "name": "Panama",
  //   "code": "PA"
  // },
  // {
  //   "dialCode": "509",
  //   "name": "Haiti",
  //   "code": "HT"
  // },
  // {
  //   "dialCode": "51",
  //   "name": "Peru",
  //   "code": "PE"
  // },
  // {
  //   "dialCode": "52",
  //   "name": "Mexico",
  //   "code": "MX"
  // },
  // {
  //   "dialCode": "54",
  //   "name": "Argentina",
  //   "code": "AR"
  // },
  // {
  //   "dialCode": "55",
  //   "name": "Brazil",
  //   "code": "BR"
  // },
  // {
  //   "dialCode": "56",
  //   "name": "Chile",
  //   "code": "CL"
  // },
  // {
  //   "dialCode": "57",
  //   "name": "Colombia",
  //   "code": "CO"
  // },
  // {
  //   "dialCode": "58",
  //   "name": "Venezuela",
  //   "code": "VE"
  // },
  // {
  //   "dialCode": "591",
  //   "name": "Bolivia",
  //   "code": "BO"
  // },
  // {
  //   "dialCode": "593",
  //   "name": "Ecuador",
  //   "code": "EC"
  // },
  // {
  //   "dialCode": "595",
  //   "name": "Paraguay",
  //   "code": "PY"
  // },
  // {
  //   "dialCode": "598",
  //   "name": "Uruguay",
  //   "code": "UY"
  // },
  // {
  //   "dialCode": "60",
  //   "name": "Malaysia",
  //   "code": "MY"
  // },
  // {
  //   "dialCode": "61",
  //   "name": "Australia",
  //   "code": "AU"
  // },
  {
    "dialCode": "62",
    "name": "Indonesia",
    "code": "ID"
  },
  {
    "dialCode": "63",
    "name": "Philippines",
    "code": "PH"
  },
  // {
  //   "dialCode": "64",
  //   "name": "New Zealand",
  //   "code": "NZ"
  // },
  {
    "dialCode": "65",
    "name": "Singapore",
    "code": "SG"
  },
  // {
  //   "dialCode": "66",
  //   "name": "Thailand",
  //   "code": "TH"
  // },
  // {
  //   "dialCode": "675",
  //   "name": "Papua New Guinea",
  //   "code": "PG"
  // },
  // {
  //   "dialCode": "7",
  //   "name": "Russia",
  //   "code": "RU"
  // },
  // {
  //   "dialCode": "81",
  //   "name": "Japan",
  //   "code": "JP"
  // },
  // {
  //   "dialCode": "84",
  //   "name": "Vietnam",
  //   "code": "VN"
  // },
  // {
  //   "dialCode": "852",
  //   "name": "Hong Kong",
  //   "code": "HK"
  // },
  // {
  //   "dialCode": "855",
  //   "name": "Cambodia",
  //   "code": "KH"
  // },
  // {
  //   "dialCode": "856",
  //   "name": "Laos",
  //   "code": "LA"
  // },
  // {
  //   "dialCode": "86",
  //   "name": "China",
  //   "code": "CN"
  // },
  // {
  //   "dialCode": "880",
  //   "name": "Bangladesh",
  //   "code": "BD"
  // },
  // {
  //   "dialCode": "886",
  //   "name": "Taiwan",
  //   "code": "TW"
  // },
  // {
  //   "dialCode": "90",
  //   "name": "Turkey",
  //   "code": "TR"
  // },
  // {
  //   "dialCode": "91",
  //   "name": "India",
  //   "code": "IN"
  // },
  {
    "dialCode": "92",
    "name": "Pakistan",
    "code": "PK"
  },
  // {
  //   "dialCode": "93",
  //   "name": "Afghanistan",
  //   "code": "AF"
  // },
  // {
  //   "dialCode": "94",
  //   "name": "Sri Lanka",
  //   "code": "LK"
  // },
  // {
  //   "dialCode": "961",
  //   "name": "Lebanon",
  //   "code": "LB"
  // },
  // {
  //   "dialCode": "962",
  //   "name": "Jordan",
  //   "code": "JO"
  // },
  // {
  //   "dialCode": "964",
  //   "name": "Iraq",
  //   "code": "IQ"
  // },
  // {
  //   "dialCode": "965",
  //   "name": "Kuwait",
  //   "code": "KW"
  // },
  // {
  //   "dialCode": "966",
  //   "name": "Saudi Arabia",
  //   "code": "SA"
  // },
  // {
  //   "dialCode": "967",
  //   "name": "Yemen",
  //   "code": "YE"
  // },
  {
    "dialCode": "968",
    "name": "Oman",
    "code": "OM"
  },
  {
    "dialCode": "971",
    "name": "United Arab Emirates",
    "code": "AE"
  },
  // {
  //   "dialCode": "972",
  //   "name": "Israel",
  //   "code": "IL"
  // },
  // {
  //   "dialCode": "973",
  //   "name": "Bahrain",
  //   "code": "BH"
  // },
  {
    "dialCode": "974",
    "name": "Qatar",
    "code": "QA"
  },
  // {
  //   "dialCode": "976",
  //   "name": "Mongolia",
  //   "code": "MN"
  // },
  // {
  //   "dialCode": "977",
  //   "name": "Nepal",
  //   "code": "NP"
  // },
  // {
  //   "dialCode": "992",
  //   "name": "Tajikistan",
  //   "code": "TJ"
  // },
  // {
  //   "dialCode": "993",
  //   "name": "Turkmenistan",
  //   "code": "TM"
  // },
  // {
  //   "dialCode": "994",
  //   "name": "Azerbaijan",
  //   "code": "AZ"
  // },
  // {
  //   "dialCode": "995",
  //   "name": "Georgia",
  //   "code": "GE"
  // },
  // {
  //   "dialCode": "998",
  //   "name": "Uzbekistan",
  //   "code": "UZ"
  // },
  // {
  //   "dialCode": "varies",
  //   "name": "All other countries",
  //   "code": "??"
  // }
]