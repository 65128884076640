import { useEffect } from "react";
import { appConfig } from "../config";

const ClickupRedirect = () => {
  useEffect(() => {
    window.location.replace(appConfig.CLICKUP_REDIRECT_URL!)
  }, [])

  return null; 
}

export default ClickupRedirect;